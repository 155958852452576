@import "../../application/style/app.less";
@import (inline) "app-uztelecom-font.css";
@import "app-uztelecom-defenition.less";

.nprogress(@karaoke-uztelecom-color-blue);

/* override begin */
.image-content-branding (@image-path) {
  //content: url(@image-path);

  background-image: url(@image-path);
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-content-full-branding (@image-path) {
  width: image-width(@image-path);
  height: image-height(@image-path);

  .image-content-branding (@image-path);
}

/* override end */

a {
  color: @karaoke-uztelecom-color-blue;
  text-decoration: underline @karaoke-uztelecom-color-blue;
}

a:focus, a:hover {
  color: @karaoke-uztelecom-color-blue;
  text-decoration: underline @karaoke-uztelecom-color-blue;
}

input {
  .karaoke-uztelecom-style-input()
}

// ---------------------------------------------------------------------------------------------------------------------

.app-header-line {
  position: relative;
  border: none;
  //border-bottom: 1px solid;
  border-bottom: 0;
  z-index: 1;
  text-align: center;
  text-decoration: none;
  .karaoke-uztelecom-font-regular();

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    //@color-old: #00B850;
    //background: @color-old; /* Old browsers */
    background: transparent; /* Old browsers */

    //background-image: url('./images/app-line-header.png');
    //background-repeat: repeat-y;
    //background-size: 100% auto;

  }

  /*&:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border: 1px solid #3DA379;
    opacity: 0.15;
  }*/

  .text {
    .karaoke-uztelecom-font-title();
    //font-weight: bold;
    font-size: 26px;
    line-height: 28px;
    margin-top: 2px;
    margin-bottom: 4px;
    display: inline;

    padding-left: unset;
    text-align: left;
    //padding-top: 6px;
    color: @karaoke-uztelecom-color-blue;
  }

  &.btn {
    text-align: center;
  }

  .icon, .arrow {

    @karaoke-header-line-icon-size: 42px;
    //top: -4px;

    width: @karaoke-header-line-icon-size;
    height: @karaoke-header-line-icon-size;

    display: none;
  }

  //&.section-feedback .icon:before {
  //  background-image: url('../image/app/ico_feedback.png');
  //}

  .app-icon {
    margin-right: -5px;
  }

  .arrow {
    width: 32px;
    height: 32px;
    margin-right: -9px; // tune

    &:before {
      background-image: url('./images/icons/ico_arrow_right.png');
      background-size: 32px 32px;
    }
  }

  &.btn.active, &.btn:active {
    box-shadow: none;
  }
}

.app-header-line, a.app-header-line {
  color: @karaoke-uztelecom-color-blue;
}

.jumbotron {
  background-color: rgba(106, 35, 131, 0.08);
  border: 1px @karaoke-uztelecom-color-blue solid;
  border-radius: @karaoke-uztelecom-border-radius;
}

body {
  background: @karaoke-uztelecom-color-white; /* чтобы избежать эффекта inner shadow */
  color: @karaoke-uztelecom-color-blue;
}

#background {
  background-color: @karaoke-uztelecom-color-background;
  // filter: blur(4px);

  &.custom {
    //background-color: #ffffff;
    //background-blend-mode: soft-light;
    background-blend-mode: multiply;
    filter: sepia(100%) hue-rotate(180deg) saturate(160%) brightness(1.8) blur(4px) opacity(0.15);

    &:after {
      background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.15) 100);
    }
  }

  &:after {
    //background-color: transparent;
    background: linear-gradient(to bottom, transparent 0%, rgba(61, 99, 148, 0.15) 100);
  }

  /*background-color: #b526e5;
  background-blend-mode: hard-light;

  &:after {
    //content: none;
    //-webkit-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.4);
  }*/
}

/*.bottom-area {
  background: linear-gradient(to bottom, transparent 0%, rgba(61, 99, 148, 0.25) 100%);
}*/

@cover-border-radius: 50%;

.cover {

  border-radius: @cover-border-radius;

  &:before {
    border-radius: @cover-border-radius;
  }

  .description {
    //background: rgba(93, 18, 153, 0.6);
    background: rgba(255, 255, 255, 0.95);
    color: @karaoke-text-color;
  }

  &:hover {
    //-webkit-box-shadow: 0 0 3em #ffffff;
    box-shadow: none;
  }
}

// рекламные элементы на главной
.song-batch-carousel {
  @base-height: 120px;
  @font-size: 16px;
  @space: 2px;
  @rows: 2;
  @line-height: @font-size + @space;
  @height-desc: @font-size * @rows + @space * (@rows + 1);

  @height: @base-height + @height-desc + 10px;
  height: @height;

  .cover {

    background-color: @karaoke-uztelecom-color-white;
    border-radius: 6px;
    background-size: 80%;

    .description {

      top: 115px;
      bottom: auto;

      background: @karaoke-uztelecom-color-white;
      color: @karaoke-uztelecom-color-dark;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      text-align: center;

      .title, .author {

        font-size: @font-size;
        line-height: @line-height;
        height: @height-desc;

        .karaoke-uztelecom-font-regular();


        display: -webkit-box;
        -webkit-line-clamp: @rows;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .author {
        display: none;
      }

      .title {
        margin: 0;
      }
    }
  }

  .cover:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: @cover-border-radius;
    //border: 2px red solid;
  }
}

/*.promo-wide {

  @height: 150px;

  display: block;
  width: 100%;
  height: @height;
  background-color: #089F61;
  //background-image: url('./images/promo-wide-back.png');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto @height;

  .wrapper {
    display: block;
    width: 100%;
    height: 100%;
  }

  .cover {
    width: @height;
    height: @height;

    border-radius: 0;
    &:before {
      border-radius: 0;
    }
  }

  .text-area {
    position: absolute;
    left: 150px;
    right: 0;
    top: 0;
    margin: 5px;

    .section, .author, .title {
      .app-label;
      color: white;
    }

    .section {
      font-size: 1.7em;
      margin-top: 10px;
      margin-bottom: 12px;
      height: 1em;
    }

    .author, .title {
      font-size: 1.1em;
      margin-top: 5px;
    }
  }

  .rn-carousel-control {
    display: none;
  }

  .rn-carousel-indicator {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;

    @size: 8px;
    height: @size;
    //bottom: 0;
    bottom: @size;

    span {

      &.active {
        color: #fff;
        content: '';

        background-color: #ffffff;
      }

      color: #666;
      display: inline-block;
      font-size: 15px;

      text-indent: -9999px;
      width: @size;
      height: @size;
      border-radius: @size/2;

      margin: 0 1px;
      margin-bottom: @size;
      border: 1px #ffffff solid;

    }
  }

  .cover {
    //@color: @karaoke-mf-violet;
    @color1: #004F90;
    @color2: #5b94ff;

    //box-shadow: @color1 0 0 50px, @color2 0 0 75px; // optimization

    &:hover {
      box-shadow: @color1 0 0 50px, @color2 0 0 75px;
    }
  }
}*/

.loader {

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    .image-content("./images/art_loading_notes.svg");
    animation: rotate 3.33s linear infinite;
    background-size: cover;
    color: @karaoke-uztelecom-color-white;
  }
}

.catalog-item {
  .album {
    color: @karaoke-uztelecom-color-blue;
    background: transparent;
    text-decoration: none;
    font-style: unset;

    &:hover {
      color: @karaoke-uztelecom-color-blue;
      text-decoration-color: @karaoke-uztelecom-color-blue;

      box-shadow: unset;
    }
  }
  a.cover {
    text-decoration: none;
  }

  overflow: hidden;
}

.catalog-item, .cover {
  .description {

    background: fade(@karaoke-uztelecom-color-blue, 70%);
    color: @karaoke-uztelecom-color-white;

    & > * {
      margin: 0.5em 0.2em;
    }

    .author {
      .karaoke-uztelecom-font-regular();
      font-size: 0.78em;
    }

    .title {
      .karaoke-uztelecom-font-bold();
      font-size: 0.92em;
    }
  }
}

@media @desktop {
  @cover-border-radius: 6px;

  .cover {
    border-radius: @cover-border-radius;

    &:before {
      border-radius: @cover-border-radius;
    }
  }

  .catalog-item {
    .cover:after {
      border-radius: @cover-border-radius;
      background: none;
    }
  }
}

#loader {
  background: @karaoke-uztelecom-color-blue;
  color: @karaoke-uztelecom-color-white;
}

.bootbox.modal .modal-dialog {
  .model-content {
    background-color: #590c89;
  }

  .modal-footer {
    border-top: 1px solid #282828;

    .btn.btn-primary {
      background-color: @karaoke-uztelecom-color-blue;
      border-color: @karaoke-uztelecom-color-blue;
    }
  }

  .modal-content .modal-body .modal-footer {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

body #nprogress {
  @color: @karaoke-uztelecom-color-white;

  .bar {
    background: @color;
  }

  .peg {
    box-shadow: 0 0 10px @color, 0 0 5px @color;
  }

  .spinner-icon {
    border-top-color: @color;
    border-left-color: @color;
  }
}

.app-label.traffic-free {
  font-size: 0.95em;
  text-align: right;
  font-style: italic;
  margin-top: -17px;
  margin-right: -15px;
  color: rgb(8, 160, 98);
  color: white;
  text-shadow: 1px 1px 3px #2F2F2F;
}

.button-send, .disclaimer .button-subscribe {

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  text-decoration: none;

  &:active, &:focus, &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: @karaoke-uztelecom-color-blue;
    text-decoration: none;
  }
}

.art-play-finish {
  background-image: url('./images/art_play_finish.png');
}

#main .playlist-panel.affix {
  //border-radius: 32px;
  overflow: hidden;

  margin-top: -5px;
  left: 5px;
  right: 5px;
  box-shadow: 1px 1px 4px #777;
  background-color: rgba(255, 255, 255, 0.96);

  @color: @karaoke-uztelecom-color-blue;

  .app-header-line {
    //background-color: @karaoke-uztelecom-color-white;
    //box-shadow: 1px 1px 4px #777;
    color: @color;
    border-bottom: 0;
    .text {
      margin-left: 15px;
      color: @color;
    }
    .note {
      //color: @color;
      margin-right: 18px;
    }

    .arrow {
      //display: none;
      margin-right: 5px;
    }
  }
}

.karplayer {

  @karaoke-text: @karaoke-primary-accent-color;

  .control-block {
    background-color: transparent;
    margin: 0;
    padding: 10px 0;

    .song {
      font-weight: bold;
      color: @karaoke-uztelecom-color-dark;
    }
  }

  .progress-bar {
    background-color: @karaoke-primary-accent-color;
  }

  .kar-text.ready {

    .karaokeBlock.speakingBlock span[data-begin].speaking {
      color: @karaoke-text;
    }

    .karaokeBlock.speakingBlock span[data-begin].speaking.finish {
      color: @karaoke-text;
    }

    span[data-begin].speaking {
      color: @karaoke-text;
    }

    span[data-begin].speaking.finish {
      color: @karaoke-text;
    }
  }

  a.play-next:hover {
    background: @karaoke-primary-accent-color;
  }

  .kar-button-main {

    .btn-primary {
      background-color: @karaoke-uztelecom-color-blue;
      border-color: @karaoke-uztelecom-color-blue;
    }

    .stop {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_play.png");
    }

    .pause {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_play.png");
    }

    .play {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_stop.png");
    }

    .load {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_wait.png");
    }
  }
}


.pagination > li > a, .pagination > li > span {

  border-radius: 25px !important;
  min-width: 30px;
  padding: 4px 0;
  margin: 0 2px;

  background-color: white;
  color: rgb(86, 6, 139);
  border: 1px solid #55058A;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  color: #939393;
  background-color: @karaoke-uztelecom-color-blue;
  border-color: #939393;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  color: #fff;
  background-color: @karaoke-uztelecom-color-blue;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  color: #ffffff;
  background-color: #56068B;
}

.bootbox.modal, .bootbox.modal.modal-auth, .bootbox.modal.modal-subscribe-song {

  &.modal.large {
    .modal-dialog {
      .modal-content {
        .bootbox-close-button {
          margin-right: 0;
        }
      }
    }
  }

  .karaoke-uztelecom-font-regular();

  .modal-dialog {

    margin: @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile 0 @karaoke-dim-page-padding-mobile;

    .modal-content {
      margin: 0;

      border-radius: 8px;
      border: 1px solid lightgrey;

      background-color: white;
      color: @karaoke-uztelecom-color-blue;
      text-align: left;
      font-weight: 200;

      font-size: 1.1em;

      .bootbox-close-button {
        .hide-internal-text();

        //background-color: @karaoke-uztelecom-color-dark;
        background-image: url("./images/icons/ico_close.png");
        padding: 4px 9px 8px;
        line-height: 20px;
        font-size: 20px;
        border-radius: 16px;
        width: 32px;
        height: 32px;
        display: block;
        opacity: 1;
      }

      .modal-body .bootbox-close-button {
        margin-right: -10px;
      }

      .bootbox-body {
        padding-top: 1.5em;
      }

      .modal-footer {
        text-align: center;
        border-top: 0;
        //border-top: 1px solid #EDEDED;
        padding: 10px 0;
        margin-bottom: 10px;

        .btn {
          .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-white, @karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-blue);
          width: auto;
          min-width: 40%;

          &:before, &:after {
            display: none;
          }
        }

        .btn.btn-success, .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
          .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);

          &:before, &:after {
            display: none;
          }
        }

        .btn.btn-primary {
          .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);

          &:before, &:after {
            display: none;
          }
        }
      }

      b {
        font-size: 1.1em;
        color: #333;
      }
    }
  }
}

// фикс заголовка меню MegafonPro для совместимости с табличной вёрсткой сервиса
#main .page-header-main-content {
  //.template-header;

  display: table-row;
  width: 100%;
  //height: 0;
  height: 50px;

  &:after {
    content: "";
    display: block;
    border-bottom: 1px solid #282828;
    height: 20px;
  }

  .page-sliding-aside-handle {
    position: static;
    //transform: translate(100%, -150%);
    transform: translate(100%, 50%);
  }

  .logo {
    margin-left: 35px;
    margin-top: 10px;
    height: 0;
  }

  .restriction-badge {
    position: static;
    float: right;
    margin-top: 25px;
    margin-right: 15px;
  }
}

.app-header-line, .app-header-line.btn {
  .karaoke-uztelecom-font-bold();
  text-align: left;

  .arrow {
    display: block;
  }
}

//ul[rn-carousel].song-batch-carousel {
.song-batch-carousel {
  background: transparent;

  margin-left: -18px;
  margin-right: -18px;
  width: auto;
  overflow: hidden;

  .rn-carousel-controls .rn-carousel-control {
    &:before {
      content: '';
      position: relative;
      padding: 0;
      margin: 20% 0;
      height: 80%;
      box-shadow: 0 0 15px @karaoke-uztelecom-color-blue, 0 0 15px @karaoke-uztelecom-color-blue, 0 0 15px @karaoke-uztelecom-color-blue inset;
      border-radius: 50%;
      background-color: @karaoke-uztelecom-color-blue;
    }

    &.rn-carousel-control-prev:before {
      right: 75%;
    }

    &.rn-carousel-control-next:before {
      right: -75%;
    }
  }
}

.app-label {
  text-shadow: none;
}

/*.page-block-main a {
  //color: @karaoke-uztelecom-color-dark;
  //text-decoration: underline @karaoke-uztelecom-color-dark;
  //transition: color 100ms, text-decoration 100ms;

  &:focus, &:hover {
    color: #cdcdcd;
    text-decoration: underline #c6c6c6;
  }
}*/

.page-block {
  .karaoke-uztelecom-font-regular();
}

// cтилизация отдельных страниц будет здесь
.page {
  &.profile > .page-block-main > .content {

    .section {
      margin-top: 2em;

      /*.title {
        color: rgb(151, 151, 151);
      }*/

      &.status {
        .title {
          float: left;
          margin-top: 5px;
        }

        .text {
          background: @karaoke-uztelecom-color-blue;
          opacity: 0.9;
          border: 1px solid @karaoke-uztelecom-color-blue;
          box-sizing: border-box;
          border-radius: 100px;
          color: @karaoke-uztelecom-color-white;
          padding: 2px 10px;
          display: inline-block;
          margin-top: 4px;

          float: right;
        }
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .phone {
      font-size: 1.2em;
      font-weight: bold;
    }

    .comment {
      color: @karaoke-uztelecom-color-dark;
      font-size: 0.8em;
      //font-size: 11.2px;
    }

    .logout {
      margin-top: 0.6em;
      display: inline-block;
    }

    input.text {
      .karaoke-uztelecom-style-input();
      width: 100%;
      padding: 12px 15px;
      color: @karaoke-uztelecom-color-blue;
    }

    .button-send {
      .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);
    }

    .submit {
      text-align: center;
    }

    @media @desktop {
      .content.two-column {
        .right .settings {
          margin: 78px 0 0 32px;
        }
      }
    }

    @media @phone {
      .avatar img {
        width: 96px;
      }
    }
  }
}



//copypaste

.page-history .section-playlist.secondary {
  display: none;
}
.section-playlist {

  .subtext {
    display: block;
    font-size: 0.8em;

    margin-top: -6px; // margin-bottom: 6px
    margin-bottom: 6px;
  }

  .app-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;

    &.clear {
      .image-content-branding("./images/icons/ico_close.png");
    }

    &.filter {
      //.image-content-branding("./images/ico_filter.png");
      background-image: url("./images/icons/ico_filter.png");
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

//~~~~~~~~~ Мои исполнения
song-item {
  &.event-play .play {
    background-image: url('./images/icons/ico_play.png');

  }
  &.event-perf .play {
    background-image: url('./images/icons/ico_record.png');
  }
  &.event-perf .playlist-action {
    //background-image: url('./images/icons/ico_close.png');
    transform: rotate(45deg);
    //background-image: none;
    //content: 'Удалить';
    //display: inline-block;
  }
}

button-row.history-mode {

  .icon {
    width: 42px;
    height: 42px;
    display: block;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    background-size: 100%;
  }

  .name {
    // todo: flexbox
    margin-top: 11px;
    margin-bottom: 11px;
  }

  & .state-all .icon {
    background-image: url("./images/icons/ico_play.png");
  }

  & .state-rec .icon {
    background-image: url("./images/icons/ico_record.png");
  }
}

@media @desktop {
  .page-history {
    .section-playlist.app-header-line {
      .subtext, .filter {
        display: none;
      }
    }

    .section-playlist.secondary {
      display: block;
      margin-bottom: 18px;

      & > * {
        display: inline-block;
        width: 250px;
      }

      .label {
        position: relative;
        color: #828282;
        padding-left: 58px;
        text-align: left;
        font-weight: normal;
        font-size: 1em;
        float: left;
        margin-top: 26px;

        .app-icon {
          left: 12px;
          top: -5px;
          right: auto;
          opacity: 0.6;
          float: left;
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }

      button-row {
        display: inline-block;
        width: 250px;
        .name {
          white-space: nowrap;
        }

        &.active .song-item {
          //background-color: @karaoke-primary-accent-color;
        }
      }
    }

    .grid-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 0 2vw;
    }
  }
}

@media @tablet-and-up {
  .page {
    //margin-bottom: 25px; // некрасиво из-за градиента
  }
}

@media @tablet-and-up {
  .bootbox.modal.modal-auth .modal-dialog, .bootbox.modal .modal-dialog, .bootbox.modal.modal-subscribe-song .modal-dialog {
    margin: 15px auto 0;
  }

  .bootbox.modal {
    .modal-footer {
      text-align: left;
      .btn, .btn + .btn {
        display: inline-block;
        margin: 0 20px 0 0;
        min-width: 224px;
      }
    }
  }
}

//@import "element-disclaimer";
@import "element-catalog";
//@import "element-cover";

@media @desktop {
  .song-batch-carousel, carousel {
    display: none;
  }
}

.playlist-panel .note {
  background-color: @karaoke-uztelecom-color-blue;
  color: @karaoke-uztelecom-color-white;
  border-radius: 14px;
  padding: 1px 8px;
  margin: 4px 18px 0 0;
}

@media @desktop {
  #main .playlist-panel.affix {
    margin-left: 32px;
    margin-right: 32px;
  }
}

.button-send, .player-finish .btn-primary.btn {
  .karaoke-uztelecom-style-button(@karaoke-uztelecom-color-blue, @karaoke-uztelecom-color-white);
  width: auto;
  min-width: 225px;
}

.bootbox {
  .modal-dialog {
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
      color: @karaoke-uztelecom-color-dark;
    }
    .modal-header {
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: @karaoke-uztelecom-color-blue;
      }
      border-bottom: 0;
      padding-bottom: 0;
    }
    .modal-body{
      .bootbox-body {
        padding-top: 0 !important;
      }
      .song-item {
        .name {
          font-weight: bold;
          font-size: 16px;
        }
        .author {
          font-size: 14px;
        }
        .name, .author, .catalog {
          color: @karaoke-uztelecom-color-dark;
        }
      }
    }

  }
}

.player-finish {
  .score, .upload {
    color: @karaoke-uztelecom-color-dark;
  }
  @media @desktop {
    & {
      .block-first {
        //background-color: @karaoke-uztelecom-color-white;
        background-color: transparent;
        .score, .upload {
          color: @karaoke-uztelecom-color-dark;
        }
      }
      .block-second {
        //background-color: @karaoke-uztelecom-color-white;
        background-color: transparent;
      }

      .art-play-finish {
        background-color: transparent;
        border: none;
        overflow: unset;
        border-radius: unset;
      }
    }
  }
}

body {
  // Полноэкраные модальные окна
  @media (max-width: 768px) {
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;

      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0 !important;
      }
    }
  }
}

button-row.record-mode {
  .ink {
    background-color: @karaoke-uztelecom-color-blue;
  }

  .state-battle .icon {
    background-image: url("./images/ic_mode_record_battle.png");
  }

  .state-duet .icon {
    background-image: url("./images/ic_mode_record_duet.png");
  }

  .state-off .icon {
    background-image: url("./images/ic_mode_record_off.png");
  }

  .state-on .icon {
    background-image: url("./images/ic_mode_record_on.png");
  }
}

player-button {
  .pb-icon {
    &.state-stop {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_play.png");
    }

    &.state-pause {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_play.png");
    }

    &.state-play {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_stop.png");
    }

    &.state-load {
      background-image: url("../../application/karaoke/player/components/uztelecom/player_wait.png");
      animation: fa-spin 2s infinite linear;
    }

    @media @desktop {
      &.state-stop { background-image: url("../../application/karaoke/player/components/uztelecom/player_play_desktop.png"); }
      &.state-pause { background-image: url("../../application/karaoke/player/components/uztelecom/player_play_desktop.png"); }
      &.state-play { background-image: url("../../application/karaoke/player/components/uztelecom/player_stop_desktop.png"); }
      &.state-load { background-image: url("../../application/karaoke/player/components/uztelecom/player_wait_desktop.png"); }
    }
  }

  //.pb-label {
  //  .karaoke-uztelecom-font-small();
  //  color: @karaoke-uztelecom-color-dark;
  //}
}

player-button.sing .pb-icon {
  background-image: url("../../application/karaoke/player/components/uztelecom/player_sing.png");
}

player-button.record {
  .pb-icon {
    &.state-disabled {
      background-image: url("../../application/karaoke/player/components/uztelecom/recorder_disabled.png");
    }

    &.state-off {
      background-image: url("../../application/karaoke/player/components/uztelecom/recorder_disabled.png");
    }

    &.state-on {
      background-image: url("../../application/karaoke/player/components/uztelecom/recorder_enabled.png");
    }

    &.state-duet {
      background-image: url("../../application/karaoke/player/components/uztelecom/recorder_duet_enabled.png");
    }

    &.state-battle {
      background-image: url("../../application/karaoke/player/components/uztelecom/recorder_battle_enabled.png");
    }
  }
}


body {
  .icon-volume-user {
    background-image: url("./images/ico_volume_user@2x.png");
  }

  .icon-volume-phone {
    background-image: url("./images/ico_volume_phone@2x.png");
  }

  .rzslider {

    .rz-bar {
      background: @karaoke-uztelecom-color-gray-light;
    }

    .rz-selection {
      background: @karaoke-uztelecom-color-blue
    }

    .rz-pointer {
      width: 16px;
      height: 16px;
      top: -6px;
      background-color: @karaoke-uztelecom-color-blue;
      border: 2px solid @karaoke-uztelecom-color-white;
    }
  }

  .karplayer.mobile {
    .media.control-block.playback {
      .media-left {
        padding-right: 0;
      }
      .media-object {
        width: 42px;
        height: 42px;
        background-size: 42px 42px;
        margin: 8px 0 0 2px;
      }
    }
  }

  .karplayer.desktop {
    .rzslider {
      width: 90%
    }
  }
}




@media @desktop {

  .page-history .section-playlist.secondary .label {
    color: @karaoke-uztelecom-color-white;
  }

  .player-finish .art-play-finish {
    border: none;
  }

  .player-finish .art-play-finish {
    border: 0;
  }
}

.bootbox {
  a {
    color: @karaoke-uztelecom-color-blue;
    text-decoration: none;
    a:focus, a:hover {
      color: @karaoke-uztelecom-color-blue;
      text-decoration: underline @karaoke-uztelecom-color-blue;
    }
  }
}

@media @phone {
  .page-history {
    .app-header-line .text {
      margin-right: 32px; // отступ для иконки
    }
  }
}

//////////////

// разделители между разделами, кроме первого элемента

@media @phone {
  promo {
    margin-bottom: 20px;
  }

  promo ~ promo {
    border-top: 1px solid @karaoke-uztelecom-color-divider;
    padding-top: 10px;
    margin-top: 20px;
  }
}

// todo: right place

.siri-container.desktop{
  color: @karaoke-uztelecom-color-white;
}
.siri-container.mobile{
  color: @karaoke-uztelecom-color-blue;
}

.page-playback .siri-container {
  color: @karaoke-uztelecom-color-blue;
}

.page-playback {
  .karplayer.desktop {
    .sing-label, .download-label {
      color: @karaoke-uztelecom-color-blue;
    }
  }
}

.page-history .section-playlist.secondary {
  .label {
    color: @karaoke-uztelecom-color-dark;
  }

  .song-item .name {
    color: @karaoke-uztelecom-color-dark;
    font-family: "Roboto Slab", arial, serif;
  }

  .active {
    .song-item .name {
      color: @karaoke-uztelecom-color-dark;
    }
  }


}
