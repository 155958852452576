@import "../app-defenitions";

@karaoke-uztelecom-color-blue: #3D6394;
@karaoke-uztelecom-color-white: #FFFFFF;
@karaoke-uztelecom-color-yellow: #FFEE2D;
@karaoke-uztelecom-color-dark: #1F2229;
@karaoke-uztelecom-color-gray: #8D959D;
@karaoke-uztelecom-color-gray-light: #DADADA;

@karaoke-uztelecom-color-background: #F1F8FF;
@karaoke-uztelecom-color-border: #2C69A1;
@karaoke-uztelecom-color-divider: #CEE0F6;

@karaoke-uztelecom-border-radius: 10px;

@karaoke-primary-accent-color: @karaoke-uztelecom-color-blue;

@karaoke-text-color: @karaoke-uztelecom-color-blue;
// ---------------------------------------------------------------------------------------------------------------------
// original
@karaoke-search-text-color: @karaoke-uztelecom-color-dark;
@karaoke-search-placeholder-color: @karaoke-uztelecom-color-gray;
@karaoke-search-border-color: @karaoke-uztelecom-color-gray-light;
@karaoke-search-button-active-color: @karaoke-uztelecom-color-blue;

.karaoke-uztelecom-font-title() {
  font-family: "Roboto Slab", arial, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
}

.karaoke-uztelecom-font-regular() {
  font-family: "PT Sans", arial, serif;
  font-weight: 400;
  font-size: 14px;
}

.karaoke-uztelecom-font-small() {
  .karaoke-uztelecom-font-regular();
  font-size: 11.2px;
}

.karaoke-uztelecom-font-large() {
  .karaoke-uztelecom-font-regular();
  font-size: 16px;
}

.karaoke-uztelecom-font-bold() {
  font-family: "PT Sans", arial, serif;
  //.karaoke-mobiuz-font-regular();
  font-weight: 700;
}

.karaoke-uztelecom-style-button(@color-back, @color-text) {
  .karaoke-uztelecom-style-button(@color-back, @color-text, @color-back);
}

.karaoke-uztelecom-style-button(@color-back, @color-text, @color-border) {
  .karaoke-uztelecom-font-bold();

  display: inline-block;

  font-size: 14px;
  text-align: center;
  line-height: 36px;

  border-radius: 10px;
  background-color: @color-back;
  color: @color-text;
  border: 1px solid @color-border;
  /* min-width: 100px; */
  padding: 7px 10px;
  //text-transform: uppercase;
  transition: background-color ease-in-out 0.1s;

  &:focus, &:hover {
    color: @color-text;
    background-color: hardlight(@color-back, #666666);
  }
}

.karaoke-uztelecom-style-input() {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(43, 22, 80, 0.78);
  box-sizing: border-box;
  border-radius: @karaoke-uztelecom-border-radius;
  padding: 8px 16px;
  color: @karaoke-uztelecom-color-blue;

  &::placeholder {
    color: @karaoke-uztelecom-color-dark;
    //padding-left: 5px;
  }
}

/*.image-content-branding-m (@image-name) {
  background-image: url("./mobiuz/@{image-name}");
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}*/

// from app-*.less -----------------------------------------------------------------------------------------------------

@section-text-color: #ffffff;


//@section-main-bg-color: @karaoke-mf-green;
@section-main-bg-color: transparent;
@section-main-border-color: @section-main-bg-color;

@section-catalog-bg-color: @section-main-bg-color;
@section-catalog-border-color: @section-main-border-color;

@section-popular-bg-color: @section-main-bg-color;
@section-popular-border-color: @section-main-border-color;

@section-playlist-bg-color: @section-main-bg-color;
@section-playlist-border-color: @section-main-border-color;

@section-new-bg-color: @section-main-bg-color;
@section-new-border-color: @section-main-border-color;

@section-feedback-bg-color: @section-main-bg-color;
@section-feedback-border-color: @section-main-border-color;


//@section-main-hover-bg-color: @karaoke-mf-green-dark;
@section-main-hover-bg-color: transparent;
@section-main-hover-border-color: @section-main-hover-bg-color;

@section-catalog-hover-bg-color: @section-main-hover-bg-color;
@section-catalog-hover-border-color: @section-main-hover-border-color;

@section-popular-hover-bg-color: @section-main-hover-bg-color;
@section-popular-hover-border-color: @section-main-hover-border-color;

@section-playlist-hover-bg-color: @section-main-hover-bg-color;
@section-playlist-hover-border-color: @section-main-hover-border-color;

@section-new-hover-bg-color: @section-main-hover-bg-color;
@section-new-hover-border-color: @section-main-hover-border-color;

@section-feedback-hover-bg-color: @section-main-hover-bg-color;
@section-feedback-hover-border-color: @section-main-hover-border-color;
